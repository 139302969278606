// extracted by mini-css-extract-plugin
export var EventHighlights = "EventHighlights-module--EventHighlights--57320";
export var Highlights = "EventHighlights-module--Highlights--068f1";
export var btnRound = "EventHighlights-module--btnRound--2ebf5";
export var cardBg = "EventHighlights-module--cardBg--bb04d";
export var cardText = "EventHighlights-module--cardText--ddc0e";
export var cards = "EventHighlights-module--cards--f3922";
export var crossIcon = "EventHighlights-module--crossIcon--b3fb5";
export var date = "EventHighlights-module--date--4dd10";
export var event = "EventHighlights-module--event--4843d";
export var formControl = "EventHighlights-module--form-control--797f8";
export var heading = "EventHighlights-module--heading--f9807";
export var highlightsImg = "EventHighlights-module--highlightsImg--0aa58";
export var main = "EventHighlights-module--main--32d3e";
export var meetup = "EventHighlights-module--meetup--44834";
export var meetupBtn = "EventHighlights-module--meetupBtn--e2132";
export var round = "EventHighlights-module--round--83ccd";
export var searchInput = "EventHighlights-module--searchInput--6f5e4";
export var searchbar = "EventHighlights-module--searchbar--1f04b";