import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import dot from "../../images/event-listing/dot.png"
import * as styles from "./PressHQ.module.scss"

function PressHQ({ upComingPost, setTimeComplete, timeComplete, setOpen }) {
  const [timerDays, setTimerDays] = useState("00")
  const [timerHours, setTimerHours] = useState("00")
  const [timerMinutes, setTimerMinutes] = useState("00")
  const [timerSeconds, setTimerSeconds] = useState("00")
  const [isTimeEnd, setIsTimeEnd] = useState(false)
  const endDateTime = new Date(upComingPost?.eventEndTime + " UTC").getTime()

  let interval = useRef()
  const dateTime = new Date(upComingPost?.eventStartTime + " UTC").getTime()
  const startTimer = () => {
    const countdownDate = new Date(
      timeComplete ? endDateTime : dateTime
    ).getTime()

    interval = setInterval(() => {
      const now = new Date().getTime()
      const distance = countdownDate - now

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (distance < 0) {
        // stop out timer
        if (timeComplete) {
          setIsTimeEnd(true)
        } else {
          setTimeComplete(true)
        }
        timeComplete && isTimeEnd && clearInterval(interval.current)
      } else {
        setTimerDays(days)
        setTimerHours(hours)
        setTimerMinutes(minutes)
        setTimerSeconds(seconds)
      }
    }, 1000)
  }

  useEffect(() => {
    if (timeComplete) {
      new Date(endDateTime).getTime() - new Date().getTime() > 0
        ? startTimer()
        : setIsTimeEnd(true)
    } else {
      new Date(dateTime).getTime() - new Date().getTime() > 0
        ? startTimer()
        : setTimeComplete(true)
    }
    return () => {
      clearInterval(interval.current)
    }
  })

  return (
    <div className={styles.pressHQ}>
      <Container>
        <div>
          <div className={styles.heading}>
            <h1>Press HQ</h1>
            <p>Company Highlights. Latest Victories. Events in Focus.</p>
          </div>

          <div className={styles.global}>
            <div className={styles.textTimer}>
              <div
                className={styles.globalText}
                style={{
                  background: `url(${upComingPost?.featuredImage?.localFile?.publicURL})`,
                }}
              >
                <div className={styles.overlay}></div>
                {!(
                  upComingPost?.duration?.toLowerCase() === "nil" &&
                  upComingPost?.location?.toLowerCase() === "nil" &&
                  upComingPost?.booth?.toLowerCase() === "nil"
                ) && (
                  <div className={styles.venue}>
                    {upComingPost?.duration?.toLowerCase() !== "nil" && (
                      <h6>{upComingPost?.duration}</h6>
                    )}
                    {upComingPost?.location?.toLowerCase() !== "nil" && (
                      <img
                        decoding="async"
                        loading="lazy"
                        src={dot}
                        alt="dots"
                      />
                    )}
                    {upComingPost?.location?.toLowerCase() !== "nil" && (
                      <h6>{upComingPost?.location}</h6>
                    )}
                    {upComingPost?.booth?.toLowerCase() !== "nil" && (
                      <img
                        decoding="async"
                        loading="lazy"
                        src={dot}
                        alt="dots"
                      />
                    )}
                    {upComingPost?.booth?.toLowerCase() !== "nil" && (
                      <h6>{upComingPost?.booth}</h6>
                    )}
                  </div>
                )}
                <h2>{upComingPost?.title}</h2>
                <div className={styles.viewBtn}>
                  {timeComplete && isTimeEnd ? (
                    <Link
                      to={`/events/${upComingPost?.buttons[1]?.url}/`}
                      variant="light"
                      className="btn_white2_border"
                    >
                      {upComingPost?.buttons[1]?.title}
                    </Link>
                  ) : (
                    <Button
                      variant="light"
                      className="btn_white2_border"
                      onClick={() => setOpen(true)}
                    >
                      {upComingPost?.buttons[0]?.title}
                    </Button>
                  )}
                </div>
              </div>
              {!timeComplete && (
                <div className={styles.timer}>
                  <div className={styles.days}>
                    <div className={styles.text}>
                      <h3>{timerDays}</h3>
                      <p>Day</p>
                    </div>
                  </div>
                  <div className={styles.days}>
                    <div className={styles.text}>
                      <h3>{timerHours}</h3>
                      <p>Hours</p>
                    </div>
                  </div>
                  <div className={styles.days}>
                    <div className={styles.text}>
                      <h3>{timerMinutes}</h3>
                      <p>Minutes</p>
                    </div>
                  </div>
                  <div className={styles.days}>
                    <div className={styles.text}>
                      <h3>{timerSeconds}</h3>
                      <p>Seconds</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.logo}>
              <img
                decoding="async"
                loading="lazy"
                src={upComingPost?.logo?.localFile?.publicURL}
                alt={upComingPost?.logo?.alternativeText || upComingPost?.title}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PressHQ
