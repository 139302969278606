import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import bg from "../../images/event-listing/bg.png"
import dot from "../../images/event-listing/dot.png"
import search from "../../images/event-listing/search.svg"
import * as styles from "./EventHighlights.module.scss"

function EventHighlights({ allPosts, CTA, setOpen }) {
  const length = 4

  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [searching, setSearching] = useState(true)

  const [list, setList] = useState([...allPosts?.slice(0, length)])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const isMore = currentLength < allPosts?.length
      const nextResults = isMore
        ? allPosts?.slice(currentLength, currentLength + length)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    if (searching) {
      const isMore = list.length < allPosts?.length
      setHasMore(isMore)
    }
  }, [list, allPosts])

  const handleLoadMore = () => {
    setLoadMore(true)
  }
  const [searchTerm, setSearchTerm] = useState("")
  const changeHandler = e => {
    if (e?.target?.value === "") {
      setSearching(true)
      setList([...allPosts?.slice(0, length)])
    }
    setSearchTerm(e?.target?.value || "")
  }
  const handleSearch = e => {
    e?.preventDefault()
    let newPosts = allPosts?.filter(post =>
      post?.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setList([...newPosts?.slice(0, length)])
    setSearching(false)
    setHasMore(false)
  }

  return (
    <div className={styles.event}>
      <Container>
        <div className={styles.main}>
          <div className={styles.heading}>
            <h2>
              Event <span className="text-primary">Highlights</span>
            </h2>
          </div>
          <div className={styles.searchbar}>
            <InputGroup className={styles.round}>
              <FormControl
                type="text"
                aria-label="Search"
                placeholder="Search event name"
                aria-describedby="Newsletter"
                name="search"
                value={searchTerm}
                onChange={changeHandler}
                onKeyPress={e => (e.key === "Enter" ? handleSearch(e) : "")}
                className={styles.searchInput}
              />
              <InputGroup.Append>
                <Button
                  className={styles.btnRound}
                  type="submit"
                  aria-label="Search"
                  onClick={handleSearch}
                >
                  <img
                    decoding="async"
                    loading="lazy"
                    src={search}
                    alt="search"
                    width={19}
                    height={19}
                  />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <hr />
        <div className={styles.EventHighlights}>
          <div
            style={{
              maxWidth: "845px",
            }}
          >
            <div className={styles.cards}>
              {list?.map(
                (e, i) =>
                  i < 2 && (
                    <div className={styles.Highlights}>
                      <div className={styles.cardBg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={bg}
                          alt="dots"
                        />
                      </div>
                      <div className={styles.cardText}>
                        <div className={styles.date}>
                          <h6>{e?.event_category?.name}</h6>
                          <img
                            decoding="async"
                            loading="lazy"
                            src={dot}
                            alt="dots"
                          />
                          <h6>{e?.heldAt}</h6>
                        </div>
                        <Link to={`/events/${e?.slug}/`}>
                          <h3>{e?.title}</h3>
                        </Link>
                      </div>
                      <div className={styles.highlightsImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.featuredImage?.localFile?.publicURL}
                          alt={e?.featuredImage?.alternativeText || e?.title}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className={styles.cards}>
              {list?.map(
                (e, i) =>
                  i > 1 && (
                    <div className={styles.Highlights}>
                      <div className={styles.cardBg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={bg}
                          alt="background"
                        />
                      </div>
                      <div className={styles.cardText}>
                        <div className={styles.date}>
                          <h6>{e?.event_category?.name}</h6>
                          <img
                            decoding="async"
                            loading="lazy"
                            src={dot}
                            alt="dots"
                          />
                          <h6>{e?.heldAt}</h6>
                        </div>
                        <Link to={`/events/${e?.slug}/`}>
                          <h3>{e?.title}</h3>
                        </Link>
                      </div>
                      <div className={styles.highlightsImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.featuredImage?.localFile?.publicURL}
                          alt={e?.featuredImage?.alternativeText || e?.title}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className={styles.meetupBtn}>
              {hasMore && (
                <button
                  variant="light "
                  className="btn_white2_border"
                  onClick={handleLoadMore}
                >
                  Load more
                </button>
              )}
            </div>
          </div>
          {searching && (
            <div className={styles.meetup}>
              <img
                decoding="async"
                loading="lazy"
                src={CTA?.featuredImage?.localFile?.publicURL}
                alt={CTA?.featuredImage?.alternativeText || CTA?.title}
              />
              <h4>{CTA?.title}</h4>
              <h3>{CTA?.subTitle}</h3>
              <div className={styles.meetupBtn}>
                <Button
                  variant="light "
                  style={{ fontSize: "12px" }}
                  className="btn_white2_border"
                  onClick={() => setOpen(true)}
                >
                  {CTA?.buttons[0].title}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}
export default EventHighlights
