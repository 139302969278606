import { graphql } from "gatsby"
import React, { useState } from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import ModelMeeting from "../components/common/meeting-model/Booking"
import EventHighlights from "../components/event-listing/EventHighlights"
import PressHQ from "../components/event-listing/PressHQ"
import MainLayout from "../layouts/MainLayout"

const HireFlutterDeveloper = ({ data }) => {
  const allPosts = data?.allStrapiEventpost?.nodes
  const upComingPost = data?.allStrapiEventUpcoming?.nodes
  const CTA = data?.allStrapiEventUpcoming?.nodes[0]?.cta
  const [timeComplete, setTimeComplete] = useState(false)
  const [show, setShow] = React.useState(false)

  return (
    <MainLayout>
      <ModelMeeting
        show={show}
        setShow={setShow}
        eventStartDate={upComingPost[0]?.eventStartTime}
        eventEndData={upComingPost[0]?.eventEndTime}
        eventName={CTA?.subTitle}
      />
      <PressHQ
        upComingPost={upComingPost[0]}
        setTimeComplete={setTimeComplete}
        setOpen={setShow}
        timeComplete={timeComplete}
      />
      <EventHighlights
        allPosts={allPosts}
        setOpen={setShow}
        CTA={CTA}
        timeComplete={timeComplete}
      />
    </MainLayout>
  )
}

export const query = graphql`
  query EventPost {
    allStrapiEventpost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        slug
        heldAt(formatString: "DD MMMM, YYYY")
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        event_category {
          name
        }
      }
    }
    allStrapiEventUpcoming {
      nodes {
        booth
        duration
        eventStartTime(formatString: "DD MMMM, YYYY, hh:mm:ss a")
        eventEndTime(formatString: "DD MMMM, YYYY, hh:mm:ss a")
        location
        slug
        title
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        eventImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        buttons {
          title
          url
        }
        cta {
          title
          subTitle
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
      }
    }
  }
`

export default HireFlutterDeveloper

export const Head = () => {
  return (
    <SEORevamp
      title="InvoZone Press HQ: Discover Tech Innovations, Insights & Events."
      description="Unlock the world of InvoZone's tech breakthroughs and industry accolades. Dive into our press releases, news articles, and media buzz at InvoZone Press HQ"
      image="https://invozone-backend.s3.amazonaws.com/Terms_and_Conditions_1_3786cf7091.webp"
    />
  )
}
