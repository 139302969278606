// extracted by mini-css-extract-plugin
export var days = "PressHQ-module--days--89dc4";
export var global = "PressHQ-module--global--70a5b";
export var globalText = "PressHQ-module--globalText--52e60";
export var heading = "PressHQ-module--heading--60de0";
export var logo = "PressHQ-module--logo--469cc";
export var overlay = "PressHQ-module--overlay--69ea5";
export var pressHQ = "PressHQ-module--pressHQ--5d13c";
export var text = "PressHQ-module--text--c1833";
export var textTimer = "PressHQ-module--textTimer--1dcf0";
export var timer = "PressHQ-module--timer--6aac3";
export var venue = "PressHQ-module--venue--b61b2";
export var viewBtn = "PressHQ-module--viewBtn--7a0c6";